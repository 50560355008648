<template>
	<tr class="tr-monteiA-infos">
		<td class="monteIA-horseFiche">
			<router-link :to="{ name: 'horseFiche', params: { horse_id: params.schedulehorse_mare.horse_id }}">
				{{ params.schedulehorse_mare.horse_nom }}
			</router-link>
		</td>
		<td>{{ params.schedulehorse_mare.horse_transpondeur }}</td>
		<td>
			<template v-if="sms">{{ sms.seasonmarestallion_status }}</template>
			<template v-else-if="mare_status">{{ $t('gynecologie.'+mare_status.marestatus_code) }}</template>
		</td>
		<td>
			<template v-if="params.schedulehorse_contact.contact_id">{{ params.schedulehorse_contact.contact_firstname }} {{ params.schedulehorse_contact.contact_lastname }}</template>
			<template v-else-if="params.schedulehorse_tiers.tiers_id">{{ params.schedulehorse_tiers.tiers_rs }}</template>
		</td>
		<td>
			<template v-if="params.schedulehorse_contact.contact_id"><template v-if="params.schedulehorse_contact.phones.length > 0">{{ formatPhone(params.schedulehorse_contact.phones[0].phone_combine) }}</template></template>
			<template v-else-if="params.schedulehorse_tiers.tiers_id"><template v-if="params.schedulehorse_tiers.phones.length > 0">{{ formatPhone(params.schedulehorse_tiers.phones[0].phone_combine) }}</template></template>
		</td>
		<td>
			<template v-if="params.schedulehorse_mare.horse_nom">
				<br class="d-md-none">
				<b-button v-if="!contrat.avenant_contract" :class="class_contract" size="sm" variant="secondary btn-min-with" @click="createContract">
				{{ $t('monte.contrat_a_faire') }} <font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
				</b-button>
				<b-button v-else-if="contrat.avenant_status == 3" class="green" size="sm" variant="secondary btn-min-with" @click="openContract">
					{{ $t('monte.contrat_signe') }} <font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
					<span :class="class_contract"> €</span>
				</b-button>
				<b-button v-else class="red" size="sm" variant="secondary btn-min-with" @click="openContract">
					{{ $t('monte.contrat_a_signe') }} <font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
					<span :class="class_contract"> €</span>
				</b-button>
			</template>
		</td>
		<td>
			<b-button v-if="params.schedulehorse_mare.horse_nom" size="sm" @click="openActe" :class="season_mare && season_mare.seasonmare_sanitary ? 'green' : 'red'">
				{{ $t('monte.sanitaire') }} <font-awesome-icon :icon="['fal', 'syringe']"></font-awesome-icon>
			</b-button>
		</td>
		<td>
			<b-button-group class="ml-2 ml-md-0">
				<b-dropdown @click="edit" :text="$t('monte.modifier')" split variant="secondary btn-sm ">
					<b-dropdown-item v-if="params.schedulehorse_mare.horse_nom" @click="openAddActe">{{ $t('monte.saillie') }}</b-dropdown-item>
					<b-dropdown-item @click="deleteSchedule">{{ $t('monte.supprimer') }}</b-dropdown-item>
				</b-dropdown>
			</b-button-group>
		</td>
		<td>
			{{ commentaire_formatted }}
		</td>
		<td>
			<template v-if="params.schedulehorse_mare.lieustationnement">
				{{ params.schedulehorse_mare.lieustationnement }}
			</template>
		</td>
	</tr>
</template>

<style scoped>
	.btn.red {
		border-color: #F21207;
		color: #F21207;
	}
	.btn.green {
		border-color: #569E18;
		color: #569E18;
	}
</style>

<script type="text/javascript">
	import Vue from 'vue'
	import Contract from '@/mixins/Contract'
	import Tiers from '@/mixins/Tiers'
	import Gynecologie from '@/mixins/Gynecologie'
	import Shutter from '@/mixins/Shutter'
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: 'CellCalendarIA',
		mixins: [Contract, Tiers, Gynecologie, Shutter, MonteShutters],
		props: ['schedule', 'params', 'season_id', 'edit_schedule', 'delete_schedule', 'previous_season'],
		data () {
			return {
				contrat: {},
				mare_status: null,
				season_mare: null,
				class_contract: '',
				sms: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.params.schedulehorse_mare.horse_id) {
					const contrats = await this.loadContracts(this.params.schedulehorse_mare.horse_id)

					const avenant = contrats.filter(avenant => {
						if(avenant.contract.length === 0 || !avenant.contract.contract_contractconfig || avenant.contract.config.length === 0) return false
						return avenant.contract.config.horse.horse_id == this.params.schedulehorse_stallion.horse_id
						&& avenant.contract.config.season.season_id == this.season_id
						&& avenant.avenant_id == avenant.contract.avenant.avenant_id
					})

					if(avenant.length == 0) {
						this.contrat = {avenant_status: 0}
					} else {
						this.contrat = avenant[0];
						if(this.contrat.contract.contract_tiers.length > 0 && window.navigator.onLine) {
							const tiers_id = this.contrat.contract.contract_tiers[0].tiers.tiers_id
							const factures = await this.getFactureSoldeTiers(tiers_id)
							this.class_contract = factures ? 'd-none' : 'text-danger'
						}
					}

					const season_mare = await this.loadSeasonMareStallionBySeasonHorse(this.params.schedulehorse_mare.horse_id, this.season_id)
					if(season_mare.length > 0) {
						this.season_mare = season_mare[0]
					}
					const previous = await this.loadSeasonMareStallionBySeasonHorse(this.params.schedulehorse_mare.horse_id, this.previous_season)
					if(previous.length > 0) {
						this.sms = await this.getSeasonMareStallionDefaultBySeasonMare(previous[0].seasonmare_id)
					}
					if(!this.sms &&  this.season_mare) {
						const status = await this.loadMareStatus()
						this.mare_status = status.find(stat => stat.marestatus_id == this.season_mare.seasonmare_status);
					}
				}
			},

			async createContract() {
				if(this.params.schedulehorse_mare.horse_id) {
					const tiers = await this.loadTiersHorse(this.params.schedulehorse_mare.horse_id)
					if(tiers.length == 0) {
						this.failureToast("monte.jument_no_tiers")
						this.showAjoutTiers()
						return false
					}

					this.contrat.contract = {contract_id: await this.addContractFromHorse(this.params.schedulehorse_mare.horse_id)}
				} else {
					this.contrat.contract = {contract_id: await this.addContractFromStallion()}
				}
				this.openContract()
			},

			showAjoutTiers() {
				this.shutterPanel().keepGiven('monte-ia-calendar')
				const shutter = this.monteShutters['ajout-tiers-mare']
				shutter.props.horse = this.params.schedulehorse_mare

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-tiers-mare')
					this.createContract()
				}

				this.shutterPanel().open(shutter)
			},

			async openContract() {
				if(this.shutterPanel().isOpen('contrat')) {
	                this.shutterPanel().close('contract-a-valider')
	                this.shutterPanel().close('contract-bons-valider')
	                this.shutterPanel().close('contract-invoices')
					this.shutterPanel().close('contrat')
					return false
				}

				this.shutterPanel().keepGiven('monte-ia-calendar')
				const shutter = this.monteShutters['contrat']
				shutter.props.contract_id = this.contrat.contract.contract_id
				shutter.props.stallion_id = this.params.schedulehorse_stallion.horse_id

				shutter.onOk = () => {
					this.shutterPanel().close('contrat')
					this.init_component()
				}

				this.shutterPanel().open(shutter)
			},

			edit() {
				this.$emit('update:edit_schedule', {schedule_info: this.params, schedule_id: this.schedule, horse_id: this.params.schedulehorse_stallion.horse_id, season_mare: this.season_mare})
			},

			deleteSchedule() {
				this.$emit('update:delete_schedule', {schedulehorse_id: this.params.schedulehorse_id})
			},

			openActe() {
				if(this.shutterPanel().isOpen('acte-jument')) {
					this.shutterPanel().close('acte-jument')
					return false
				}

                this.shutterPanel().keepGiven('monte-ia-calendar')
				const shutter = this.monteShutters['acte-jument']
				shutter.props.horse_id = this.params.schedulehorse_mare.horse_id
				shutter.props.horse_nom = this.params.schedulehorse_mare.horse_nom

				shutter.onOk = () => {
					this.shutterPanel().close('acte-jument')
				}

				this.shutterPanel().open(shutter)
			},

			openAddActe() {
				if(this.shutterPanel().isOpen('ajout-acte')) {
					this.shutterPanel().close('ajout-acte')
					return false
				}

				this.shutterPanel().keepGiven('monte-ia-calendar')
				const shutter = this.monteShutters['ajout-acte']
				shutter.props.horses_ids = [this.params.schedulehorse_mare.horse_id]
				shutter.props.actes_ids = []
				shutter.props.group_preselect = "REPRO"
				shutter.props.type_preselect = "REPRO_INSMINATION_ARTIFICIELLE_EN_FRAIS"
				shutter.props.date_preselect = this.params.schedulehorse_date
				shutter.props.preselect = this.params.schedulehorse_stallion.horse_nom

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-acte')
				}

				this.shutterPanel().open(shutter)
			},

			formatPhone(number) {
				return new AsYouType(Vue.i18n.locale()).input(number)
			}
		},

		computed: {
			commentaire_formatted() {
				return this.params.schedulehorse_commentaire// ? this.params.schedulehorse_commentaire.substr(0, 25) + '...' : ''
			}
		}
	}

</script>